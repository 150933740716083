'use client'

import cn from 'classnames'
import Headroom from 'react-headroom'

import { Inner } from '@/app/src/widgets/Layout/Header/ui/Inner'
import { NavigationMobile } from '@/app/src/widgets/Layout/Header/ui/NavigationMobile'

import { useHeader } from '@/app/src/widgets/Layout/Header/model'

import './Header.scss'

export type IProps = {
  className?: string
  variant?: 'white' | 'transparent'
}

const Header = ({ className, variant = 'white' }: IProps) => {
  const {
    isScrolled,
    isOpenedSubmenu,
    isPinned,
    isOpenedMobileMenu,
    setStateSubmenuOpened,
    handlePin,
    handleUnPin,
    toggleMobileMenu,
  } = useHeader()

  return (
    <header
      className={cn('header', {
        [`${className}`]: !!className,
        'header--submenu-is-opened': isOpenedSubmenu,
        'header--mm-is-opened': isOpenedMobileMenu,
        'header--is-pinned': isPinned,
        'header--variant-white': variant === 'transparent',
        'header--is-scrolled': isScrolled,
      })}
    >
      <Headroom pinStart={690} onPin={handlePin} onUnpin={handleUnPin} onUnfix={handleUnPin}>
        <Inner headroom />
      </Headroom>

      <Inner
        isVariantWhite={variant === 'transparent'}
        onChangeSubmenu={setStateSubmenuOpened}
        onChangeMobileMenu={toggleMobileMenu}
        isOpenedMobileMenu={isOpenedMobileMenu}
      />

      <div className="header__mobile-navigation-wrapper">
        <NavigationMobile />
      </div>
    </header>
  )
}

export default Header
