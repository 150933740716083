import cn from 'classnames'
import './Container.scss'

export type IProps = {
  children: React.ReactNode
  className?: string
  size?:
    | '3xs'
    | '2xs'
    | 'xs'
    | 's'
    | 's-up'
    | 's-2up'
    | 'sm'
    | 'm'
    | 'l'
    | 'xl'
    | '2xl'
    | '3xl'
    | 'infinity'
    | 'without-container'
}

const Container = ({ children, size = 'l', className = '' }: IProps) => {
  return (
    <div
      className={`${cn('container', `container--size-${size}`, {
        [`${className}`]: !!className,
      })}`}
    >
      {children}
    </div>
  )
}

export default Container
