'use client'

import cn from 'classnames'

import Link from 'next/link'
import { useCollapse } from 'react-collapsed'
import { Icon } from '@/app/src/shared/ui/Icon'
import { FoodtechSlot } from '@/app/src/widgets/Layout/Header/ui/FoodtechSlot'
import { Button } from '@/app/src/shared/ui/Button'

import { usePathname } from 'next/navigation'
import { nav } from '@/app/src/shared/config/navigation'

import './NavigationMobile.scss'
import { INextLink } from '@/app/src/shared/model'
import { AnyIconName } from '@/app/src/shared/ui/Icon/lib'

export type IProps = {
  className?: string
}

const NavigationMobile = ({ className }: IProps) => {
  return (
    <nav
      className={cn('navigation-mobile', {
        [`${className}`]: !!className,
      })}
    >
      <ul className="navigation-mobile__list">
        {nav.map((item) => (
          <Item key={item.id} itemMenu={item} />
        ))}
      </ul>

      <div className="navigation-mobile__button">
        <Button href="/project-evaluation#main" fullWidth size="l">
          Estimate Project
        </Button>
      </div>
    </nav>
  )
}

type PropsItem = {
  itemMenu: (typeof nav)[number]
}

const Item = ({ itemMenu }: PropsItem) => {
  const pathname = usePathname()
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  const hasSubmenu = !!itemMenu?.subnav.length
  const hasFoodtech = itemMenu.hasFoodtech

  return (
    <li
      className={cn('navigation-mobile__nav-item', {
        'navigation-mobile__nav-item--submenu-opened': isExpanded,
      })}
    >
      <Link
        href={`${itemMenu?.href}#main` as INextLink}
        className={cn('navigation-mobile__link', {
          'navigation-mobile__link--active': pathname?.startsWith(itemMenu.href as string),
        })}
      >
        {itemMenu?.title}
      </Link>

      {hasSubmenu && (
        <>
          <div className={cn('navigation-mobile__open-menu-button-wrap')}>
            <button {...getToggleProps()} className={cn('navigation-mobile__open-menu-button')}>
              <Icon width="22" height="22" name="arrows/chevron-down" />
            </button>
          </div>

          <div className={cn('navigation-mobile__subnav-wrap')} {...getCollapseProps()}>
            {hasFoodtech && (
              <div className="navigation-mobile__foodtech-slot">
                <FoodtechSlot />
              </div>
            )}

            <ol className={cn('navigation-mobile__subnav')}>
              {itemMenu.subnav.map(({ title, href, id, iconName, description, isComingSoon }) => (
                <li key={id} className={cn('navigation-mobile__nav-subitem')}>
                  <Link
                    href={href}
                    className={cn('navigation-mobile__nav-link', {
                      'navigation-mobile__nav-link--active': pathname?.startsWith(href as string),
                      'navigation-mobile__nav-link--disabled': isComingSoon,
                    })}
                  >
                    {iconName && (
                      <div
                        className={cn('navigation-mobile__nav-icon', {
                          'navigation-mobile__nav-icon--has-foodtech':
                            hasFoodtech || itemMenu.id === 1,
                          'navigation-mobile__nav-icon--disabled': isComingSoon,
                        })}
                      >
                        <Icon name={iconName as AnyIconName} width={32} height={33} />
                      </div>
                    )}
                    <div>
                      <div>{title}</div>
                      {description && (
                        <div className="navigation-mobile__nav-description">{description}</div>
                      )}
                      {isComingSoon && (
                        <div className="navigation-mobile__nav--is-coming-soon">coming soon</div>
                      )}
                    </div>
                  </Link>
                </li>
              ))}
            </ol>
          </div>
        </>
      )}
    </li>
  )
}

export default NavigationMobile
